<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>订单管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row type="flex" :gutter="20">
            <el-col :span="2"
              ><el-tag type="info" effect="dark">条件查询</el-tag></el-col
            >
            <el-col style="display: flex;" :span="22">
              <el-input
                size="medium"
                placeholder="请输入预订人"
                v-model="query.customer"
              >
              </el-input>
              <el-input
                size="medium"
                style="margin: 0 20px;"
                placeholder="请输入订单编号"
                v-model="query.order_no"
              >
              </el-input>
              <el-input
                size="medium"
                placeholder="请输入联系电话"
                v-model="query.phone"
              >
              </el-input>
              <el-button
                type="success"
                icon="el-icon-search"
                size="mini"
                @click="getList"
                >查询</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column type="expand">
              <template v-slot="scope">
                <el-row style="padding-left: 10px;margin-bottom:10px;">
                  <el-col :span="8">
                    规格名称： {{ scope.row.list[0].spec_key_name }}
                  </el-col>
                  <el-col :span="8">
                    房间名称： {{ scope.row.list[0].room_name }}</el-col
                  >
                  <el-col :span="8">
                    价格： {{ scope.row.list[0].price }}
                  </el-col>
                </el-row>

                <el-table border :data="scope.row.list[0].check_list">
                  <el-table-column
                    label="入住id"
                    prop="check_in_id"
                  ></el-table-column>
                  <el-table-column
                    label="姓名"
                    prop="customer"
                  ></el-table-column>
                  <el-table-column
                    label="身份证号"
                    prop="id_number"
                  ></el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="order_no" width="230" label="订单号">
            </el-table-column>
            <el-table-column prop="customer" label="预订人"> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="number" label="入住人数"> </el-table-column>
            <el-table-column prop="phone" width="150" label="手机号">
            </el-table-column>
            <el-table-column label="类型">
              <template v-slot="scope">
                <el-tag type="success" v-if="scope.row.type === 0">
                  全天房
                </el-tag>
                <el-tag type="warning" v-if="scope.row.type === 1">
                  钟点房
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="info" v-if="scope.row.status === 1">
                  已取消
                </el-tag>
                <el-tag v-if="scope.row.status === 2">
                  已入住
                </el-tag>
                <el-tag type="danger" v-if="scope.row.status === 3">
                  已完成
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  已预订
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="300" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="openInfo(scope.row)"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-edit-outline"
                  @click="pAdd(scope.row)"
                  >录入信息</el-button
                >
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-house"
                  @click="checkout(scope.row)"
                  >退房</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-row>
        <el-button type="success" @click="addInfo" size="mini">+增加</el-button>
      </el-row>
      <el-table :data="form.customer_info">
        <el-table-column label="客户姓名" prop="customer">
          <template v-slot="scope">
            <el-input
              size="mini"
              style="width:100px"
              @change="handleChange"
              v-model="scope.row.customer"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="身份证号" prop="id_number">
          <template v-slot="scope">
            <el-input
              size="mini"
              min="1"
              type="number"
              style="width:180px"
              @change="handleChange"
              v-model="scope.row.id_number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button
              size="mini"
              type="danger"
              @click="delInfo(scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'
import {
  getOrderList,
  orderInfo,
  checkin,
  checkout,
} from '@/api/room/roomstatemanage.js'

import {
  addStore,
  getStoreList,
  getInfo,
  editStore,
  delStore,
} from '@/api/room/store.js'
export default {
  data() {
    return {
      customerInfo: [],
      price_id: '',
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      btnLoading: false,
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
        order_no: '',
        customer: '',
        phone: '',
        create_time: '',
      },
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'cname',
        children: 'sub',
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        shop_name: {
          required: true,
          message: '请填写店铺名称',
          trigger: 'blur',
        },
      },
      title: '录入顾客信息',
      addOrEdit: 1,
      formLabelWidth: '160px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      form: {
        order_id: '',
        customer_info: [],
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    async openInfo(row) {
      const { data: res } = await orderInfo({ order_id: row.order_id })
    },
    //文件转base64
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function() {
          imgResult = reader.result
        }
        reader.onerror = function(error) {
          reject(error)
        }
        reader.onloadend = function() {
          resolve(imgResult)
        }
      })
    },
    getFile(file, fileList) {
      //  console.log('plan change')
      this.isEdit = true
      // this.editPlan = true
      if (this.beforeAvatarUpload(file)) {
        this.getBase64(file.raw).then((res) => {
          //     console.log(res)
          this.form.pic = res
          //   console.log(this.form.plan_pic)

          // this.ruleForm.imagePath = res
        })
      }
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt5M
    },
    exceed() {
      this.$message({
        message: '只能上传一张图片',
        type: 'warning',
      })
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      let q = new FormData()
      for (let key in this.query) {
        q.append(key, this.query[key])
      }
      const { data: res } = await getOrderList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data
        this.exp.total = res.exp.num
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '录入顾客信息'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        order_id: '',
        customer_info: [],
      }

      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
      this.form.order_id = row.order_id
      this.price_id = row.list[0].price_id
      this.form.customer_info.push({
        price_id: this.price_id,
        customer: '',
        id_number: '',
      })
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑店铺'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        shop_id: row.shop_id,
      })
      console.log(res.data)
      //   this.form.class_name = res.data.class_name
      //   this.form.class_status = res.data.class_status
      this.form.shop_id = res.data.shop_id
      this.form.address = res.data.address
      this.form.desc = res.data.desc
      this.form.shop_name = res.data.shop_name
      this.form.latitude = res.data.latitude
      this.form.longitude = res.data.longitude
      this.form.pic = res.data.pic

      this.form.status = res.data.status
      this.form.area_id = [
        res.data.area_class1,
        res.data.area_class2,
        res.data.area_class3,
      ]
    },
    addInfo() {
      this.form.customer_info.push({
        price_id: this.price_id,
        customer: '',
        id_number: '',
      })
    },
    delInfo(row, index) {
      console.log(row, index)
      this.form.customer_info.splice(index, 1)
    },
    // 退房
    checkout(row) {
      console.log(row)
      this.$confirm('确认退房？')
        .then(async (_) => {
          let f = new FormData()
          f.append('room_id', row.list[0].room_id)
          f.append('price_id', row.list[0].price_id)
          const { data: res } = await checkout(f)
          if (res.code === 1) {
            this.$message.success('退房成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    async confirmAE(e) {
      console.log(e)

      if (this.addOrEdit === 1) {
        // 添加
        let query = lodash.cloneDeep(this.form)
        console.log(query)
        query.customer_info = JSON.stringify(query.customer_info)
        var formData = new FormData()
        for (let key in query) {
          formData.append(key, query[key])
        }
        this.btnLoading = true
        console.log(query)
        const { data: res } = await checkin(formData)
        if (res.code === 1) {
          this.$message.success('录入成功')
          this.getList()
          this.cancelAE()
        } else {
          this.$message.error(res.message)
        }
        this.btnLoading = false
      } else {
        // 编辑

        let query = lodash.cloneDeep(this.form)
        query.area_id = query.area_id[query.area_id.length - 1]
        var formData = new FormData()
        formData.append('shop_name', query.shop_name)
        formData.append('shop_id', query.shop_id)
        formData.append('pic', query.pic)
        formData.append('status', query.status)
        formData.append('area_id', query.area_id)
        formData.append('address', query.address)
        formData.append('longitude', query.longitude)
        formData.append('latitude', query.latitude)
        formData.append('desc', query.desc)
        this.btnLoading = true
        if (this.isEdit === true) {
          const { data: res } = await editStore(formData)
          if (res.code === 1) {
            this.$message.success('编辑成功')
            this.cancelAE()
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        } else {
          this.$message.info('没有修改任何数据')
        }
        this.btnLoading = false
      }
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
